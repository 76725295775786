// Borrowed from here:
// https://github.com/gorails-screencasts/423-stimulus-values-api-defaults/blob/master/app/javascript/controllers/countdown_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "base", "hidden", "warning", "expired" ]
  static targets = [ "hideWhenExpired", "countdown", "progressBar" ]
  
  static values = {
    endDate: String,
    startDate: String,
    warningInterval: { type: Number, default: 60 },
    refreshInterval: { type: Number, default: 1000 },
    expiredMessage: { type: String, default: "Ending now..." },
    message: { type: String, default: "${minutes}m ${seconds}s" }
  }

  connect() {
    this.warningClassApplied = false
    this.intermissionRuntimeComplete = false
    
    if (this.hasEndDateValue && this.hasStartDateValue) {
      this.startTime = new Date(this.startDateValue).getTime()
      this.endTime = new Date(this.endDateValue).getTime()
      this.totalTime = (this.endTime - this.startTime)

      this.update()
      
      this.timer = setInterval(() => {
        this.update()
      }, this.refreshIntervalValue)
    } else {
      console.error("Missing data-intermission-end-date-value attribute", this.element)
    }
  }

  disconnect() {
    this.stopTimer()
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
  
  getDifferenceBetweenTimes(startTime, endTime) {
    return (endTime - startTime)
  }

  applyWarningClass() {
    this.element.classList.add(this.warningClass)
    this.element.classList.remove(this.baseClass)
    this.warningClassApplied = true
  }

  removeWarningClass() {
    this.element.classList.add(this.baseClass)
    this.element.classList.remove(this.warningClass)
    this.warningClassApplied = false
  }

  applyExpiredClass() {
    this.element.classList.remove(this.baseClass)
    this.element.classList.add(this.expiredClass)
  }

  update() {
    const difference = this.timeDifference()
    
    if (!this.intermissionRuntimeComplete) {
      const intermissionTimeCompleted = (this.endTime - this.startTime) - difference
      const percentageComplete = (intermissionTimeCompleted/this.totalTime)*100.0
      // console.log(`intermission time completed: ${percentageComplete}`)
      
      if (percentageComplete >= 100) {
        this.progressBarTarget.setAttribute("value", 100)
        this.intermissionRuntimeComplete = true
      } else {
        this.progressBarTarget.setAttribute("value", percentageComplete)
      }
    }
    
    if (this.hasWarningClass && (difference <= this.warningIntervalValue*1000) && (difference > 0) && !this.warningClassApplied) {
      this.applyWarningClass()
    }

    if (difference < 0) {
      if (this.warningClassApplied) {
        this.removeWarningClass()
      }
      
      if (this.hasExpiredClass) {
        this.applyExpiredClass()
      }
      
      this.countdownTarget.textContent = this.expiredMessageValue
      this.stopTimer()
      
      if (this.hasHideWhenExpiredTarget) {
        this.hideHideTargets()
      }
      
      return
    }

    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((difference % (1000 * 60)) / 1000)

    this.countdownTarget.textContent = this.messageValue
      .replace("${minutes}", minutes)
      .replace("${seconds}", seconds)
  }

  timeDifference() {
    const now = new Date().getTime()
    return this.getDifferenceBetweenTimes(now, this.endTime)
  }
  
  hideHideTargets() {
    this.hideWhenExpiredTargets.forEach((hideTarget) => {
      hideTarget.classList.add(this.hiddenClass)
    })
  }
}